
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "personal-accident-house-house-insurance-claim",
  components: { Form, Field, ErrorMessage },
  prop: ["publicId"],
  data() {
    return {
      canDoBusiness: false,
      claimNature: "",
      lossDate: "",
      injurySustained: "",
      minimumDate: "",
      todaysDate: "",
      amount: "",
      medicalReport: "",
      descriptionOfIncident: "",
      acceptTerms: false,
      hospitalName: "",
      hospitalDoctorName: "",
      attendToOccupation: "",
      disableStartDate: "",
      disableEndDate: "",
      policies: [],
      items: [],
      itemPublicId: ""
      // publicId: this.$route.params.publicId,
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const submitForm = (values, { resetForm }) => {
      // console.log(values);
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const form = document.forms[0];
            const formData = new FormData(form);

            ApiService.setHeader();
            ApiService.post(variables.CLAIMS_ROUTE, formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name: "client-claims-history"
                });
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    const claimsValidator = Yup.object().shape({
      policy: Yup.mixed().required().label("Policy reference"),
      // itemPublicId: Yup.mixed()
      //   .required()
      //   .label("policy benefit limit"),
      claimNature: Yup.mixed().required().label("Claim nature"),
      lossDate: Yup.date().required().label("Date and time of incident"),
      descriptionOfIncident: Yup.string()
        .required()
        .label("Full description of incident"),
      acceptTerms: Yup.boolean()
        .oneOf([true])
        .required()
        .label("Terms & conditions")
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Personal Accident", [
        "Claims",
        "Report Claims",
        "House Insurance"
      ]);
    });

    return { submitButton1, claimsValidator, submitForm };
  },
  created() {
    // Get the current date
    this.getCurrentDate();

    // Get the list of policies associated with this claim type
    this.getPolicies();

    //Set page title
    document.title =
      "Personal Accident House Insurance Claim | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/package/HOU/HPA`)
          .then(({ data }) => {
            //Assign data to props
            this.policies = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(event) {
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.POLICIES_ROUTE}/${event.target.value}/items`
        )
          .then(({ data }) => {
            //Assign data to variables
            this.items = data.data;
            this.minimumDate = data.data[0].policyStartDate;
            this.itemPublicId = data.data[0].publicId;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumber(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));
    },
    getAttendToOccupation(event) {
      this.defaultValues();

      const value = event.target.options[event.target.selectedIndex].value;

      // If the claim nature is accident
      if (value == "Yes") {
        this.canDoBusiness = true;
      } else {
        this.defaultValues();
      }
    },
    getCurrentDate() {
      const currentYear = new Date().getFullYear();
      this.minimumDate = currentYear + "-01-01";
      this.todaysDate = variables.currentDate();
    },
    defaultValues() {
      this.canDoBusiness = false;
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    }
  }
});
